import { get } from './http'

export function getNews(page, catid) {
  // 集团新闻列表
  return get(`/api/news/news?page=${page}&pagesize=9&catid=${catid}`)
}

export function getNewsDetail(newsid) {
  // 新闻详情
  return get(`/api/news/detail?newsid=${newsid}`)
}
