<!-- 员工天地 -->
<template>
  <div class="mt-80-mb-140">
    <div class="container">
      <div class="row">
        <div
          class="news-item col-lg-4 col-md-4 col-sm-6 col-6 px-2"
          v-for="item in newsList"
          :key="item"
          @click.capture="goToDetail(item.newsid)"
        >
          <div class="item-content">
            <div class="img-box">
              <img :src="item.image" alt="" class="news-img" />
              <div class="time">
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <PaginationBox
      :total="pageObj.total_count"
      v-model:currentPage="currentPage"
    ></PaginationBox>
  </div>
</template>
<script setup>
import { useRouter } from 'vue-router'
import { getNews } from '@/server/news'
import { ref, onMounted, watch } from 'vue'
import PaginationBox from '@/components/PaginationBox'
const router = useRouter()

const newsList = ref([])
const pageObj = ref({})
const currentPage = ref(1)

onMounted(async () => {
  const r = await getNews(currentPage.value, 3)
  newsList.value = r.list
  pageObj.value = r.cur_page
})
const goToDetail = (newsid) => {
  router.push({
    path: '/NewsCenter/NewsDetailStaff',
    query: { newsid: newsid }
  })
}
watch(currentPage, async () => {
  const r = await getNews(currentPage.value, 3)
  newsList.value = r.list
  pageObj.value = r.cur_page
})
</script>
<style lang="stylus" scoped>
.news-item
  .item-content
    background #fff
    margin-bottom 30px
    height 250px
    border-radius: 5px;
    overflow hidden
    cursor pointer
    position relative
    @media screen and (max-width 768px) {
      height 150px
      margin-bottom 20px
    }
    .img-box
      position relative
      width 100%
      height 100%
      background #C2C2C2
      @media screen and (max-width 768px) {
        height 150px
      }
      .time
        position absolute
        left 0
        right 0
        bottom 0
        width: 100%
        height: 40px;
        background: #10366B;
        color #fff
        text-align left
        overflow hidden
        text-overflow ellipsis
        word-break break-all
        white-space nowrap
        padding 0 14px
        line-height 40px
        font-size: 16px;
        font-family: Source Han Sans CN;
      img
        width 100%
        height 100%
        object-fit contain
        transition: all 0.5s ease-in-out 0s;
    & > h5
      margin 30px 0 10px
      padding 0 40px
      font-size: 20px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #333333;
      overflow hidden
      text-overflow ellipsis
      word-break break-all
      white-space nowrap
      width 100%
      @media screen and (max-width 768px) {
        margin 5px 0
        padding 0 10px
        font-size: 18px;
      }
    & > p
      padding 0 40px
      font-size: 16px;
      font-family: Source Han Sans CN;
      font-weight: 500;
      color: #999999;
      display -webkit-box
      overflow hidden
      text-overflow ellipsis
      -webkit-box-orient vertical
      -webkit-line-clamp 3
      @media screen and (max-width 768px) {
        padding 0 10px
        font-size: 14px;
      }
    &::after
      content: "";
      width: 100%;
      height: 0%;
      background-color: rgba(0,0,0,0.4);
      position: absolute;
      top: 0;
      left: 0;
      z-index: 1;
      opacity: 0;
      transition: all 0.5s ease-in-out 0s;
    &:hover
      .news-img
        transform scale(1.2)
      &::after
        height 100%
        opacity 1
.pagination
  a
    cursor pointer
</style>
