<!-- 联系我们 -->
<template>
  <div class="container-fluid mt-80-mb-140">
    <div class="container">
      <div class="row top-bar">
        <div class="col-12 col-xl-5 col-xxl-5 img-left col-sm-5">
          <img :src="mainCompany.image" alt="" class="img-fluid" />
        </div>
        <div class="col-12 col-xl-7 col-xxl-7 right-bar col-sm-7">
          <div class="company-name">{{ mainCompany.name }}</div>
          <div
            class="info-item d-flex align-items-center"
            v-if="mainCompany.address"
          >
            <div class="icon icon01"></div>
            地址：{{ mainCompany.address }}
          </div>
          <div
            class="info-item d-flex align-items-center"
            v-if="mainCompany.tel"
          >
            <div class="icon icon02"></div>
            电话：{{ mainCompany.tel }}
          </div>
          <div
            class="info-item d-flex align-items-center"
            v-if="mainCompany.url"
          >
            <div class="icon icon03"></div>
            网址：{{ mainCompany.url }}
          </div>
          <div
            class="info-item d-flex align-items-center"
            v-if="mainCompany.postcode"
          >
            <div class="icon icon04"></div>
            邮编：{{ mainCompany.postcode }}
          </div>
          <div
            class="info-item d-flex align-items-center"
            v-if="mainCompany.fax"
          >
            <div class="icon icon05"></div>
            传真：{{ mainCompany.fax }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-12 col-xl-12">
          <div class="line"></div>
        </div>
      </div>
      <div class="row gy-2 gx-2 child-company-row">
        <div
          class="col-12 col-xxl-6 col-xl-6 col-md-6"
          v-for="item in us"
          :key="item"
        >
          <div class="child-company">
            <div class="name">{{ item.name }}</div>
            <div
              class="info-item d-flex align-items-center"
              v-if="item.address"
            >
              <div class="icon icon01"></div>
              <div style="flex: 1">地址：{{ item.address }}</div>
            </div>
            <div
              class="info-item d-flex align-items-center"
              v-if="item.postcode"
            >
              <div class="icon icon04"></div>
              <div class="flex:1">邮编：{{ item.postcode }}</div>
            </div>
            <div class="info-item d-flex align-items-center" v-if="item.tel">
              <div class="icon icon02"></div>
              <div class="flex:1">电话：{{ item.tel }}</div>
            </div>
            <div class="info-item d-flex align-items-center" v-if="item.fax">
              <div class="icon icon05"></div>
              <div class="flex:1">传真：{{ item.fax }}</div>
            </div>
            <div class="info-item d-flex align-items-center" v-if="item.email">
              <div class="icon icon03"></div>
              <div class="flex:1">邮件：{{ item.email }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script setup>
import { ref, onMounted } from 'vue'
import { getConcat } from '../../../server/join'

const us = ref([])
const mainCompany = ref('')
onMounted(async () => {
  const r = await getConcat()
  us.value = r.list
  mainCompany.value = r.first
})
</script>
<style lang="stylus" scoped>
.container-fluid
  background #fff !important
  .top-bar
    padding-bottom 50px
  .line
    border-bottom 2px solid rgba(32, 155, 215, .5)
  .right-bar
    .info-item
      margin-bottom 20px
      font-size: 20px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      @media screen and (max-width 768px) {font-size: 16px;margin-bottom 10px;}
    .company-name
      font-size: 40px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #333333;
      margin-bottom 40px
      @media screen and (max-width 768px) {font-size: 25px;margin-bottom 20px;margin-top 15px}
  .icon01
    background  url('../../../assets/8.png') center no-repeat
    width 30px
    height 30px
    background-size cover
  .icon02
    background  url('../../../assets/9.png') center no-repeat
    width 30px
    height 30px
    background-size cover

  .icon03
    background  url('../../../assets/10.png') center no-repeat
    width 30px
    background-size cover
    height 30px

  .icon04
    background  url('../../../assets/11.png') center no-repeat
    width 30px
    background-size cover
    height 30px

  .icon05
    background  url('../../../assets/12.png') center no-repeat
    width 30px
    background-size cover
    height 30px
  .icon
    margin-right 20px
  .child-company-row
    margin-top 50px
    .child-company
      border 1px solid #e5e5e5
      padding 20px 25px 25px
      height 323px
      @media screen and (max-width 768px){
        min-height 323px
        height auto
      }
      .name
        font-size: 24px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #209BD7;
        margin-bottom 20px
      .info-item
        margin-bottom 20px
        font-size: 16px;
        font-family: Microsoft YaHei;
        font-weight: 400;
        color: #333333;
</style>
